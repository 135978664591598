import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export { dayjs, unixTimeStampToFormattedDate, utcDateTimeToFormattedDate }

function unixTimeStampToFormattedDate(timestamp) {
  return timestamp && dayjs.unix(timestamp).format('DD-MM-YYYY')
}

function utcDateTimeToFormattedDate(utcDateTime) {
  return utcDateTime && dayjs(utcDateTime).utc().format('YYYY-MM-DD')
}
