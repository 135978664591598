export function cookieConsentEvent(consent) {
  return {
    event: 'cookie_consent_changed',
    ...cookieConsent(consent)
  }
}

export function cookieConsent({ permissions }) {
  return {
    ...permissions,
  }
}
