export const {
  OK,
  MOVED_PERMANENTLY, FOUND, SEE_OTHER, TEMPORARY,
  BAD_REQUEST, NOT_FOUND,
  INTERNAL_SERVER_ERROR,
  FORBIDDEN
} = {
  OK: 200,
  MOVED_PERMANENTLY: 301, FOUND: 302, SEE_OTHER: 303, TEMPORARY: 307,
  BAD_REQUEST: 400, NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  FORBIDDEN: 403,
}
