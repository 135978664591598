const UserInfoContext = React.createContext(null)

export function UserInfoContextProvider({ userInfo, children }) {
  return (
    <UserInfoContext.Provider
      value={userInfo}
      {...{ children }}
    />
  )
}

/**
 * @returns {import('/requestHandlers.js').UserInfo}
 */
export function useUserInfo() {
  return React.useContext(UserInfoContext)
}
