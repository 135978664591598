import { unixTimeStampToFormattedDate } from '/machinery/dayjs'
import { reportClientError } from '/machinery/reportClientError'

export function pushToDataLayer(data) {
  window.dataLayer = window.dataLayer || [];
  [].concat(data).forEach(x => {
    try {
      if (!x) throw new Error(`No data given to pushToDataLayer function`)
      window.dataLayer.push(removeUndefinedValues(x))
    } catch (e) {
      reportClientError(e)
    }
  })
}

export function trackInteraction({ title, action, type, index = undefined, extraInteractionData = {}, extraMetaData = {} } ) {
  pushToDataLayer({
    event: 'interaction',
    metadata: {
      interaction: {
        title,
        action,
        type,
        index,
        ...extraInteractionData
      },
      ...extraMetaData,
    }
  })
}

export function trackFilter({ lastchanged, searchterm, recordcount, maxresults }) {
  return pushToDataLayer({
    event: 'filtered',
    metadata: {
      user: {
        filters: {
          lastchanged,
          searchterm,
          recordcount,
          maxresults
        }
      }
    }
  })
}

export function mapJobForDataLayer(job) {
  return {
    id: job.id,
    index: job.trackingIndex,
    title: job.title,
    brand: job.company?.id || 'klm',
    area: job.jobArea?.id,
    employmenttype: job.employmentType?.label,
    education: job.education?.map(x => x.label)?.join('||'),
    location: job.location,
    level: job.scales,
    dateupdated: unixTimeStampToFormattedDate(job.updatedDate),
    datepublished: unixTimeStampToFormattedDate(job.publishedDate),
    hours: job.hoursPerWeek?.label,
    closesat: unixTimeStampToFormattedDate(job.closedDate),
    experience: job.experienceLevel?.map(x => x.label).join('/'),
    hiringmanager: job.hiringManager?.name,
    recruiter: job.recruiter?.name,
    type: job.kind.toLowerCase(),
  }
}

/**
 * Has to be fired when visiting a job detail page
 */
export function trackJobDetailView(job) {
  return pushToDataLayer({
    event: 'view_item',
    metadata: { job: mapJobForDataLayer(job) }
  })
}

/**
 * Has to be fired after typing a minimum of 3 characters in the application form
 */
export function trackApplicationStarted(job) {
  return pushToDataLayer({
    event: 'begin_checkout',
    metadata: { job: mapJobForDataLayer(job) }
  })
}

/**
 * Has to be fired after succesfully submitting the application
 */
export function trackApplicationFinished(job) {
  return pushToDataLayer({
    event: 'purchase',
    metadata: { job: mapJobForDataLayer(job) }
  })
}

function removeUndefinedValues(data) {
  return JSON.parse(JSON.stringify(data))
}
