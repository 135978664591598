import { LocationProvider } from '@kaliber/routing'
import { translateSingular, translatePlural } from '/i18n/translations'
import { ClientConfigProvider } from '/machinery/ClientConfig'
import { I18nProvider } from '/machinery/I18n'
import { reportClientError } from '/machinery/reportClientError'
import { ReportErrorProvider } from '/machinery/ReportError'
import { routeMap } from '/routeMap'
import { UserInfoContextProvider } from '/machinery/UserInfoContext'

// eslint-disable-next-line @kaliber/no-default-export
export default function ClientWrapper({ children, ...props }) {
  const { clientContext } = props

  return (
    <ReportErrorProvider reportError={clientContext.reportError || reportClientError}>
      <UserInfoContextProvider userInfo={clientContext.userInfo}>
        <ClientConfigProvider config={clientContext.clientConfig}>
          <LocationProvider initialLocation={clientContext.location} {...{ routeMap }}>
            <I18nProvider language={clientContext.language} {...{ translateSingular, translatePlural }}>
              {children}
            </I18nProvider>
          </LocationProvider>
        </ClientConfigProvider>
      </UserInfoContextProvider>
    </ReportErrorProvider>
  )
}
