import groq from 'groq'

const linkableDocumentFields = groq`_type, slug, language, _id, _type in ['jobAreaPage'] => {'jobAreaReference': jobAreaReference->{slug}}`
const linkableDocumentObjectWithTitle = groq`{ title, ${linkableDocumentFields}}`
const imageObject = groq`{alt, crop, hotspot, asset->{_id, url, extension, size, metadata{dimensions}}}`

const jobAreaTranslationProjection = groq`{ title, ${linkableDocumentFields}, jobAreaReference -> { ATSJobAreaID, slug { current } } }`

const internalLink = groq`{..., ref->{${linkableDocumentFields}, title}}`
const externalLink = groq`{..., 'target': '_blank'}`

const linkItem = groq`{
  _type == 'internalLink' => ${internalLink},
  _type == 'externalLink' => ${externalLink},
}`

const simpleContentBlock = groq`{
  ...,
  markDefs[] {
    ...,
    _type == 'internalLink' => ${internalLink},
    _type == 'externalLink' => ${externalLink}
  }
}`

const menuItem = groq`{ _key, label, link[0]${linkItem}, submenus[]{ submenuLabel, submenuLinks[]${linkItem} } }`

const jobAreaCardsObject = groq`{ title, image${imageObject}, description, tags[], jobAreaReference -> { title, slug, ATSJobAreaID }}`
const backgroundVideoObject = groq`{ quality1080, quality720, quality540, quality360 }`
const videoObject = groq`{ title, src, poster${imageObject} }`
const videoCardObject = groq`{ title, url, poster${imageObject} }`
const matchmakerObject = groq`{ plusPoints[]{title, point}, minusPoints[]{title, point}}`
const blockWithImagesAndCTAObject = groq`{ title, block[]${simpleContentBlock}, cta[]${linkItem}, images[]${imageObject}}`
const blockWithTitleAndLine = groq`{ title, content[]${simpleContentBlock}}`
const featuredDocumentsObject = groq`{ ${linkableDocumentFields}, _key, title, description, 'image': hero{...heroImage${imageObject}} }`
const featuredCampaignPageObject = groq`{ ${linkableDocumentFields}, _key, title, description, shareImage${imageObject} }`
const teamMembersObject = groq`{ title, introduction, images[]${imageObject} }`
const documentCardsObject = groq`{ cards[] -> { ${linkableDocumentFields}, _key, title, description, 'image': coalesce(image, heroImage)${imageObject}} }`
const quoteWithPortrait = groq`{ quote[]${simpleContentBlock}, source, portrait${imageObject}, imageAlignment } `
const jobListObject = groq` { title, jobIds[], button { showButton, jobFilters{ jobArea[] } } }`
const contentCardsObject = groq`{
  items[] -> {
    ...,
    _type == 'article' => ${featuredDocumentsObject},
    _type == 'project' => ${featuredDocumentsObject},
    _type == 'videoCard' => ${videoCardObject},
  }
}`

const contentObject = groq`{
  ...,
  _type == 'blockWithTitleAndLine' => ${blockWithTitleAndLine},
  _type == 'blockWithImages' => ${blockWithImagesAndCTAObject},
  _type == 'image' => ${imageObject},
  _type == 'video' => ${videoObject},
  _type == 'quoteWithPortrait' => ${quoteWithPortrait},
  _type == 'matchmaker' => ${matchmakerObject},
  _type == 'contentSlider' => ${contentCardsObject},
  _type == 'contentCards' => ${documentCardsObject},
  _type == 'teamMembers' => ${teamMembersObject},
  _type == 'jobList' => ${jobListObject},
  markDefs[] {
    ...,
    _type == 'internalLink' => ${internalLink},
    _type == 'externalLink' => ${externalLink}
  }
}`

const content = groq`content[]${contentObject}`

const jobAreaDocumentFilter = groq`
  *[_type == $type && language == $language && jobAreaReference->slug.current == $slug] |
  order(_updatedAt desc) [0]
`

const singleDocumentFilter = groq`
  *[_type == $type && language == $language && slug.current == $slug] |
  order(_updatedAt desc) [0]
`

const defaultDocumentFields = groq`
  ${linkableDocumentFields},
  _createdAt,
  _updatedAt,
  title,
  introduction,
  seo,
  'translations': ${translations(linkableDocumentObjectWithTitle)}
`

const genericDocumentFields = groq`
  ${defaultDocumentFields},
  hero,
  description,
  content[]${contentObject}
`

const jobAreaReference = groq`jobAreaReference -> { title, ATSJobAreaID, slug, _id }`
const jobAreaReferences = groq`jobAreaReferences[] -> { title, ATSJobAreaID, slug, _id }`
const featuredDocuments = groq`featuredDocuments[] -> ${featuredDocumentsObject}`
const extraFeaturedJobAreaDocuments = groq`'extraFeaturedDocuments': *[defined(slug) && _type in ['article', 'campaign', 'project'] && (references(^.jobAreaReferences[]._ref) || references(^.jobAreaReference._ref)) && !(_id in @.featuredDocuments[]._ref) && language==^.language && ^._id != _id] | order(_updatedAt desc)[0..2]${featuredDocumentsObject}`
const extraFeaturedDocuments = groq`'extraFeaturedDocuments': *[defined(slug) && _type in ['article', 'campaign', 'project'] && !(_id in @.featuredDocuments[]._ref) && language==^.language && ^._id != _id] | order(_updatedAt desc)[0..2]${featuredDocumentsObject}`

const jobAreaBenefits = groq`'jobAreaBenefits': *[_type == 'jobAreaBenefits' && jobAreaReference->ATSJobAreaID == ^.jobAreaReference->ATSJobAreaID && language == $language][0]{ benefits[]-> { _key, title, iconImage }, ${jobAreaReference} }`
const jobAreaContentSlider = groq`'jobAreaContentSlider': *[_type == 'jobAreaContentSlider' && (references(^.jobAreaReferences[]._ref) || references(^.jobAreaReference._ref)) && language == $language][0]{contentSlider${contentCardsObject}}`

export const snippets = {
  projections: {
    home: groq`{
      ${defaultDocumentFields},
      'title': titleLineOne + ' ' + titleLineTwo,
      'heroTitle': { titleLineOne, titleLineTwo },
      heroVideo${backgroundVideoObject},
      loopingVideo${backgroundVideoObject},
      heroImage${imageObject},
      bannerImage${imageObject},
      jobAreaCards[]->${jobAreaCardsObject},
      ${featuredDocuments}
    }`,
    project: groq`{
      ${genericDocumentFields},
      ${jobAreaReferences},
      ${featuredDocuments},
      ${extraFeaturedJobAreaDocuments}
    }`,
    page: groq`{
      ${genericDocumentFields},
      ${featuredDocuments},
      ${extraFeaturedDocuments}
    }`,
    privacy: groq`{
      ${defaultDocumentFields},
      ${content},
      ${featuredDocuments}
    }`,
    article: groq`{
      ${genericDocumentFields},
      ${featuredDocuments},
      ${extraFeaturedJobAreaDocuments},
      ${jobAreaReferences}
    }`,
    internal: groq`{
      ${genericDocumentFields},
      ${featuredDocuments},
      ${extraFeaturedDocuments}
    }`,
    about: groq`{
      ${defaultDocumentFields},
      milestones[] { _key, label, year, description, image${imageObject} },
      ${featuredDocuments},
      ${extraFeaturedDocuments}
    }`,
    lifeAtKLM: groq`{
      ${defaultDocumentFields},
      description,
      'cards': ${contentCardsObject},
    }`,
    faqOverview: groq`{
      ${defaultDocumentFields},
      heroImage${imageObject}
    }`,
    faq: groq`{
      ${defaultDocumentFields},
      hero{heroImage${imageObject}, secondaryImage${imageObject}},
      questionsAndAnswers[] { _key, question, answer[]${simpleContentBlock} }
    }`,
    division: groq`{
      ${defaultDocumentFields},
      heroImage${imageObject},
      abbreviation,
      ${content},
      teamMembers${teamMembersObject},
      featuredProjects[] -> ${featuredDocumentsObject},
      video${videoObject}
    }`,
    campaign: groq`{
      ${genericDocumentFields},
      ${extraFeaturedJobAreaDocuments},
      ${jobAreaReferences},
      ${jobAreaContentSlider}
    }`,
    xxlCampaignPage: groq`{
      ${genericDocumentFields},
      subpages[]->${featuredCampaignPageObject}
    }`,
    xxlCampaignSubpage: groq`{
      ${genericDocumentFields},
      heroImage${imageObject},
      heroImageSecondary${imageObject},
      introText,
    }`,
    subsidiary: groq`{
      ${defaultDocumentFields},
      heroImage${imageObject},
      ${content},
      ATSSubsidiary
    }`,
    jobAreaPage: groq`{
      ${defaultDocumentFields},
      abbreviation,
      heroImage${imageObject},
      heroImageSecondary${imageObject},
      ${content},
      seo,
      ${jobAreaBenefits},
      ${jobAreaContentSlider},
      ${jobAreaReference},
      'translations': ${translations(jobAreaTranslationProjection)},
    }`,
    jobAreaDetail: groq`{
      ${defaultDocumentFields},
      heroImageSecondary${imageObject},
      heroImage${imageObject},
      ${content},
      whereImagesAndCTA{ cta[]${linkItem}, images[]${imageObject} },
      ${jobAreaReference}
    }`,
    jobAreaBenefits: groq`{
      benefits[]-> { _key, title, iconImage },
      ${jobAreaReference}
    }`,
    jobAreaContentSlider: groq`{
      contentSlider${contentCardsObject},
      ${jobAreaReference}
    }`,
    jobsOverview: groq`{
      ${defaultDocumentFields},
      content[]${contentObject},
    }`,
    jobArea: groq`{${linkableDocumentFields}, ATSJobAreaID}`,
    cookiePolicy: groq`{
      ${defaultDocumentFields},
      content[]${contentObject},
    }`,
  },

  singletonDocument(type, projection, { language }) {
    return [
      groq`
          *[_type == $type && language == $language] |
          order(_updatedAt desc) [0] ${projection}
        `,
      { type, language }
    ]
  },

  document(type, projection, { language, slug }) {
    return [
      groq`${singleDocumentFilter} ${projection}`,
      { type, language, slug }
    ]
  },

  documents(type, projection, { language }) {
    return [
      groq`*[_type == $type && language == $language] ${projection}`,
      { type, language }
    ]
  },


  jobArea({ slug }) {
    return [
      groq`*[_type == 'jobArea' && slug.current == $slug][0]`,
      { slug }
    ]
  },

  jobAreaDocument(type, projection, { language, slug }) {
    return [
      groq`${jobAreaDocumentFilter} ${projection}`,
      { type, language, slug }
    ]
  },

  menuItems({ language }) {
    return [
      groq`*[_type == 'menu' && language == $language] | order(_updatedAt desc) [0] {
        items[]${menuItem},
        internalItems[]${linkItem}
      }`,
      { language },
    ]
  },

  footerMenuItems({ language }) {
    return [
      groq`*[_type == 'footerMenu' && language == $language] | order(_updatedAt desc) [0] { items[]${linkItem} }`,
      { language },
    ]
  },

  settings() {
    return groq`*[_id == 'settings'] | order(_updatedAt desc) [0] {shareImage, organisation}`
  },

  excludedIps() {
    return groq`*[_type == 'excludedIpAddress'].ipAddress`
  },

  translations
}

function translations(projection) {
  return groq`
      *[
        _id != ^._id &&
        _type == ^._type &&
        translationId == ^.translationId &&
        language != ^.language
      ]
      ${projection}
    `
}
